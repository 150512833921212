<template>
    <div style="margin:0 auto;width:900px;margin-top:20px">
        <Title name='确认并采购'></Title>
        <div>
            <div style="background:#fff;padding:10px 20px 40px;">
                <h3>供应商信息：</h3>
                <div v-for="item in 1" :key="item">
                    <div style="display:flex;margin-bottom:10px;margin-top:10px">
                        <div style="flex:0 0 80px;text-align:right;color:#999">供应商：</div>
                        <div style="flex:0 0 500px;">{{orderInfo.sellerClientName}}</div>
                    </div>
                    <div style="display:flex;margin-bottom:10px">
                        <div style="flex:0 0 80px;text-align:right;color:#999">联系人：</div>
                        <div>{{orderInfo.sellerContactName}}</div>
                    </div>
                    <div style="display:flex;margin-bottom:10px">
                        <div style="flex:0 0 80px;text-align:right;color:#999">电话：</div>
                        <div>{{orderInfo.sellerMobilePhone}}</div>
                    </div>
                    <div style="display:flex;border-bottom:2px dashed #999;padding-bottom:20px">
                        <div style="flex:0 0 80px;text-align:right;color:#999">地址：</div>
                        <div style="flex:0 0 500px;">
                            {{orderInfo.sellerAddress}}
                        </div>
                    </div>
                </div>


                <h3>买方信息：</h3>
                <div>
                    <div style="display:flex;margin-bottom:10px;margin-top:10px">
                        <div style="flex:0 0 80px;text-align:right;color:#999">买方：</div>
                        <div style="flex:0 0 500px;">{{orderInfo.buyderClientName}}</div>
                    </div>
                    <div style="display:flex;margin-bottom:10px;align-items:center">
                        <div style="flex:0 0 80px;text-align:right;color:#999"><span style="color:red">*</span>联系人：
                        </div>
                        <el-input v-model="order.consignee" placeholder="请输入联系人姓名" style="flex:0 0 500px;"></el-input>
                    </div>
                    <div style="display:flex;margin-bottom:10px;align-items:center">
                        <div style="flex:0 0 80px;text-align:right;color:#999"><span style="color:red">*</span>电话：
                        </div>
                        <el-input v-model="order.mobile" placeholder="请输入电话号码" :maxlength="11" style="flex:0 0 500px;"></el-input>
                    </div>
                    <div style="display:flex;border-bottom:2px dashed #999;padding-bottom:20px;align-items:center">
                        <div style="flex:0 0 80px;text-align:right;color:#999"><span style="color:red">*</span>地址：
                        </div>
                        <el-input v-model="order.address" placeholder="请输入地址" style="flex:0 0 500px;"></el-input>
                    </div>
                </div>

                <h3>商品信息</h3>
                <div style="position:relative;padding-bottom:70px;border-bottom:2px dashed #999;">
                    <el-table :data="tableData" style="width: 100%" header-row-class-name='table-header' border fit>
                        <el-table-column prop="goodsName" label="商品名称" min-width="160">
                        </el-table-column>
                        <el-table-column prop="typeName" label="产品类型" min-width="120">
                        </el-table-column>
                        <el-table-column prop="specName" label="规格型号" width="150">
                        </el-table-column>
                        <el-table-column prop="amount" label="单价(元)" width="120">
                            <template slot-scope="scope">
                                <span>{{(scope.row.amount/scope.row.supplyNum).toFixed(2)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="supplyNum" label="供货量" width="100">
                        </el-table-column>
                        <el-table-column prop="unit" label="单位" width="100">
                        </el-table-column>
                    </el-table>

                    <div style="position:absolute;right:20px;bottom:20px;">
                        <div style="display:flex;width:400px">
                            <p style="flex:auto;text-align:right">合计：</p>
                            <p style="flex-basis:150px;text-align:right">{{tableData[0].amount}} 元</p>
                        </div>
                    </div>
                </div>

                <div style="position:relative;height:120px">
                    <div style="position:absolute;right:0;top:20px">
                        <span>应付总计：</span>
                        <div style="display:inline-block;width:100px;text-align:right;">
                            <span style="color:#FC4500">{{tableData[0].amount}}</span> 元
                        </div>
                        <div
                                @click="createOrder"
                            style=" position:absolute;top:40px ;right:0 ;cursor:pointer;background: linear-gradient(to right,#F57838,#FF4603);line-height:60px;padding:0 20px;color:#fff;font-size:15px">
                            确认采购
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from '../../component/Title'
import  {getProPriceOrderInfo,createReqOrder} from '../../api'

    export default {
        props:{
            id: Number
        },
        data() {
            return {
                orderInfo: {},
                order:{
                    orderType: 'RE',//订单类型
                    consignee: '',//收货人姓名
                    mobile: '',//收货人电话
                    address: '',//收货地址
                    message: '',//订单留言
                    productCount: 0,//产品数量
                    goodsPrice: '',//产品总费用,
                    reqId: '',//竞买ID
                    reqProId:'' //报价ID
                },
                name: '',
                phone: '',
                place: '',
                tableData: [],
                confirmDisable: true
            }
        },
        components: {
            Title
        },
        created() {
            this.getOrderInfo()
        },
        methods:{
            getOrderInfo(){
                getProPriceOrderInfo({id:this.id}).then(res => {
                    this.orderInfo = res.data
                    this.tableData = res.data.goods
                    this.order.productCount = res.data.goods[0].supplyNum
                    this.order.goodsPrice = res.data.goods[0].amount
                    this.order.reqId = res.data.productId
                    this.order.reqProId = res.data.proPriceId
                    this.order.consignee = res.data.buyderName
                    this.order.mobile = res.data.buyderTel
                    this.order.address = res.data.buyderAddress
                })
            },
            createOrder() {
                if(this.order.consignee.length === 0){
                    this.$message('收货人不能为空')
                    return
                }
                if(this.order.mobile.length === 0){
                    this.$message('收货人电话不能为空')
                    return
                } else {
                    const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
                    if (!reg.test(this.order.mobile)) {
                        this.$message('请输入正确的手机号')
                        return
                    }
                }
                if(this.order.address.length === 0){
                    this.$message('收货地址不能为空')
                    return
                }
                createReqOrder(this.order).then(res => {
                    this.$notify.success({
                        title: '成功',
                        message: '订单确认成功'
                    })
                    this.$router.push({ path: '/User/Paymented', query: { orderid: res.data,customer:'buyder'}})
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/.table-header {
        .is-leaf {
            background: #E8E8E8;
            color: #666;
            text-align: center;
        }
    }

    /deep/.cell {
        text-align: center;
    }
</style>
