var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { margin: "0 auto", width: "900px", "margin-top": "20px" } },
    [
      _c("Title", { attrs: { name: "确认并采购" } }),
      _c("div", [
        _c(
          "div",
          { staticStyle: { background: "#fff", padding: "10px 20px 40px" } },
          [
            _c("h3", [_vm._v("供应商信息：")]),
            _vm._l(1, function (item) {
              return _c("div", { key: item }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "margin-bottom": "10px",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v("供应商：")]
                    ),
                    _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                      _vm._v(_vm._s(_vm.orderInfo.sellerClientName)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "margin-bottom": "10px" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v("联系人：")]
                    ),
                    _c("div", [
                      _vm._v(_vm._s(_vm.orderInfo.sellerContactName)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "margin-bottom": "10px" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v("电话：")]
                    ),
                    _c("div", [
                      _vm._v(_vm._s(_vm.orderInfo.sellerMobilePhone)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "border-bottom": "2px dashed #999",
                      "padding-bottom": "20px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v("地址：")]
                    ),
                    _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                      _vm._v(" " + _vm._s(_vm.orderInfo.sellerAddress) + " "),
                    ]),
                  ]
                ),
              ])
            }),
            _c("h3", [_vm._v("买方信息：")]),
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "margin-top": "10px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v("买方：")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.buyderClientName)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "align-items": "center",
                  },
                },
                [
                  _vm._m(0),
                  _c("el-input", {
                    staticStyle: { flex: "0 0 500px" },
                    attrs: { placeholder: "请输入联系人姓名" },
                    model: {
                      value: _vm.order.consignee,
                      callback: function ($$v) {
                        _vm.$set(_vm.order, "consignee", $$v)
                      },
                      expression: "order.consignee",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "align-items": "center",
                  },
                },
                [
                  _vm._m(1),
                  _c("el-input", {
                    staticStyle: { flex: "0 0 500px" },
                    attrs: { placeholder: "请输入电话号码", maxlength: 11 },
                    model: {
                      value: _vm.order.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.order, "mobile", $$v)
                      },
                      expression: "order.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "border-bottom": "2px dashed #999",
                    "padding-bottom": "20px",
                    "align-items": "center",
                  },
                },
                [
                  _vm._m(2),
                  _c("el-input", {
                    staticStyle: { flex: "0 0 500px" },
                    attrs: { placeholder: "请输入地址" },
                    model: {
                      value: _vm.order.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.order, "address", $$v)
                      },
                      expression: "order.address",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("h3", [_vm._v("商品信息")]),
            _c(
              "div",
              {
                staticStyle: {
                  position: "relative",
                  "padding-bottom": "70px",
                  "border-bottom": "2px dashed #999",
                },
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "header-row-class-name": "table-header",
                      border: "",
                      fit: "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsName",
                        label: "商品名称",
                        "min-width": "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "typeName",
                        label: "产品类型",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specName",
                        label: "规格型号",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "amount",
                        label: "单价(元)",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (
                                      scope.row.amount / scope.row.supplyNum
                                    ).toFixed(2)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "supplyNum",
                        label: "供货量",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "unit", label: "单位", width: "100" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      right: "20px",
                      bottom: "20px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { display: "flex", width: "400px" } },
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              flex: "auto",
                              "text-align": "right",
                            },
                          },
                          [_vm._v("合计：")]
                        ),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "flex-basis": "150px",
                              "text-align": "right",
                            },
                          },
                          [_vm._v(_vm._s(_vm.tableData[0].amount) + " 元")]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { position: "relative", height: "120px" } },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      right: "0",
                      top: "20px",
                    },
                  },
                  [
                    _c("span", [_vm._v("应付总计：")]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "inline-block",
                          width: "100px",
                          "text-align": "right",
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "#FC4500" } }, [
                          _vm._v(_vm._s(_vm.tableData[0].amount)),
                        ]),
                        _vm._v(" 元 "),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "40px",
                          right: "0",
                          cursor: "pointer",
                          background:
                            "linear-gradient(to right,#F57838,#FF4603)",
                          "line-height": "60px",
                          padding: "0 20px",
                          color: "#fff",
                          "font-size": "15px",
                        },
                        on: { click: _vm.createOrder },
                      },
                      [_vm._v(" 确认采购 ")]
                    ),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: { flex: "0 0 80px", "text-align": "right", color: "#999" },
      },
      [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
        _vm._v("联系人： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: { flex: "0 0 80px", "text-align": "right", color: "#999" },
      },
      [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
        _vm._v("电话： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: { flex: "0 0 80px", "text-align": "right", color: "#999" },
      },
      [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
        _vm._v("地址： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }